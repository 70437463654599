import common from './components/common'
import carousel from './components/carousel'
import modal from './components/modal'
import user from './components/user'
import geolocation from './components/geolocation'
import backgroundBanner from './components/background-banner'
import alertNews from './components/alert-news'
import header from './components/header'
// import lazyLoad from './components/lazy-load'
import './monkey-patch'
import accordionFaq from './components/accordion-faq'

alertNews.getNewestAlert()
alertNews.closeAlert()
backgroundBanner.renderBackgroundBanner()
carousel.initCarousel()
common.autocompleteSearch()
common.autocompleteSearchV2()
common.onScroll()
common.controlMobileSearch()
common.closeBanner()
common.controlMobileMenu()
//common.renderBanner()
// lazyLoad.renderBannerLazyLoading()
common.initMetrics()
common.fallbackImage()
geolocation.userGeolocation()
modal.controlModal()
user.userInfo()
user.controlLoggedMenu()
user.controlFavoritesLocalesBreadcrumb()
header.controlMenu()
accordionFaq.renderFaqHead()
accordionFaq.handleAnswerOpening()
